<template>
  <div id="audiences">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>
